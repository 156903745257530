
"use strict";

import UIComponent from './UIComponent.js'
import * as commonmark from '../lib/commonmark.min.js'


const ENTRY_TYPES = new Map([
  ['public', 'PUB'],
  ['draft', 'DRA'],
  ['private', 'PRI'],
])

function decodeBase64UTF8String(content){
  return decodeURIComponent( escape( atob(content) ) )
}

function getEntryHTML(content){
  return decodeURIComponent( escape( atob(content) ) )
}

export default class BlogMod extends UIComponent{
	
	constructor(confString) {
    super('div', '#Editor')
    this.setHTML(`
      <div class="entries-box"> </div> 
      <div class="labels-box"> </div>
    `)

    this.labelsBox = this.getElement('.labels-box') 
    this.entriesBox = this.getElement('.entries-box') 

    this.entryCache = new Map()
    this.listEntries // Entries already loaded for the ?list query

    this.commonmarkParser = new commonmark.Parser()
    this.commonmarkWriter = new commonmark.HtmlRenderer()

    this.labels = []
    getLabels()
    let self = this
    
    async function getLabels(){
      let response = await fetch('getLabels')
      self.labels = await response.json();
    }
  }

  getEntryHTML(content){
    return this.commonmarkWriter.render(this.commonmarkParser.parse(content)).
      replace('<a ', '<a target="_blank" ')
  }
    
  async processModParams(params){
    log('EditorMod.processModParams', params)
    this.labelsBox.innerHTML = ''
    this.entriesBox.innerHTML = ''

    if (params.startsWith('list')){ 

      let filterQuery = params.split(':')[1] // e.g. list:status=any&size=30
      // status=any
      let getQuery = ''  // default
      if (filterQuery && filterQuery !== '') getQuery = filterQuery

      if (!getQuery.includes('status')) getQuery += '&status=any'
      // Looks for the status value          
      let status = ''
      if (getQuery.includes('status')){
        let index = getQuery.indexOf('status=')+7
        status = getQuery.substring(index) 
        if (status.includes('&')){
          let ampIndex = status.indexOf('&')
          status = status.substring(0, ampIndex)
        }
      }

      //log('getQuery', getQuery, status)
      let html = `<div class="entry-type-menu"> 
        <a href="#editor?list" ${status === 'any' ? 'class="entry-type-menu-selected"' : ''}>any</a>`
      ENTRY_TYPES.forEach( (val, key) => {
        html += `<a href="#editor?list:status=${key}" ${status === key ? 'class="entry-type-menu-selected"' : ''}>${key}</a>`
      })
      html += `<a href="#editor?entry:"> + NEW ENTRY</a>`
      this.entriesBox.innerHTML = html + '</div>'

      getQuery += '&size=200'

      let response = await fetch('getEntryList?'+getQuery); // fetch('entries?id=css-vertical-align-issue');
      let entries = await response.json();

      entries.forEach( entryData => this.addEntry(entryData))
      this.labelsBox.innerHTML = getLabelsHTML(this.labels)

    }else if (params.startsWith('entry')){ // edit an entry

      let entryId = params.split(':')[1]

      if (entryId === '') {
        this.addEntryEditor( { Id: '', IsNew: true, Status:'DRA', Title: '', Labels: null, Content: ''} )
      }else{
        let response = await fetch('getEntry?id='+entryId)
        this.addEntryEditor(await response.json())
      }
    }


    function getLabelsHTML(labels){
      let html = ''
      labels.forEach( label => {
        html += '<a href="#editor?list:tag='+label+'">'+label+'</a>'
      })
      return html
    }

  }


  addEntry(entryData){
    //log('addEntry', entryData)
    let content = decodeBase64UTF8String(entryData.Content)//entryData.Content//atob(entryData.Content)
    if (entryData.Labels !== null && entryData.Labels.includes('.commonmark')){
      content = this.getEntryHTML(content)
    }

    let newElement = document.createElement('div')
    newElement.className = 'blog-entry'

    newElement.innerHTML = `
        <div class="title"><a href="#blog?${entryData.Id}">${entryData.Title}</a></div>
        <div>${entryData.Status} ${entryData.Date.substring(0,10)} <a href="#editor?entry:${entryData.Id}">EDIT</a> </div>
    `

    this.entriesBox.appendChild(newElement)

    function getLabelsHTML(labelArray){
      if (labelArray === null) return ''
      let html = ''
      labelArray.forEach( label => {
        html += '<span class="label">'+label+'</span> '
      })
      return html
    }

    }


  addEntryEditor(entryData){
       // log('addEntryEditor', entryData)
    let content = decodeBase64UTF8String(entryData.Content)

    const isCommonmark = (entryData.Labels !== null && entryData.Labels.includes('.commonmark'))

    let newElement = document.createElement('div')
    newElement.className = 'blog-entry-editor'
    newElement.innerHTML = `
      <a href="#blog?${entryData.Id}">PERMALINK</a>

      <form action="/saveEntry" method="post">

        <input type="hidden" name="isNew"  value="${entryData.IsNew ? 'true' : 'false'}">
        <input type="text" name="id" required  value="${entryData.Id}">

        <div class="entry-editor-labels" style="font-size: 0.8em">
          ${getLabelsHTML(this.labels, entryData.Labels)}
        </div>

        <select name="status" > ${getStatusTypeOptions()} </select> 

        <input type="text" name="title" required  value="${entryData.Title}">

        ${ isCommonmark ? `<div> Show HTML content<input class="htmlContentCheckbox" type="checkbox" >  </div>
        <div class="entryHtmlContent text-content" style="display: none"></div>` : ''}
        <textarea name="content" rows="30" >${content}</textarea>
        
        <input type="submit" value="Save">
      </form>
    `

    newElement.querySelector(`select[name="status"] option[value="${entryData.Status}"]`).setAttribute('selected', '') // [name="'+entryData.Status+'"]
    this.entriesBox.appendChild(newElement)

    // In case the labels has not been loaded
    if (this.labels.length === 0){
      fetch('getLabels').then(response => response.json()).then(labels => {
        this.labels = labels
        newElement.querySelector('.entry-editor-labels').innerHTML =
          getLabelsHTML(this.labels, entryData.Labels)
      })
    }

    if (isCommonmark){
      let htmlContentBox = newElement.querySelector('.entryHtmlContent')
      let contentTextarea = newElement.querySelector('textarea[name="content"]')

      newElement.querySelector('.htmlContentCheckbox').addEventListener( 'change', e => {
        htmlContentBox.style.display = e.target.checked ? '' : 'none'
        contentTextarea.style.display = e.target.checked ? 'none' : ''
        if (e.target.checked){
          htmlContentBox.innerHTML = this.getEntryHTML(contentTextarea.value)
        }
      })
    }
    

    function getLabelsHTML(labelArray, labelsSet){

      if (labelArray === null) return ''

      let html = ''
      labelArray.forEach( label => {
        let checked = (labelsSet && labelsSet.includes(label))
        html += `
          <input id="${label}-label" name="labels" type="checkbox" value="${label}" ${(checked ? 'checked' : '')}>
          <label for="${label}-label">${label}</label> 
        `
      })
      return html
    }

    function getStatusTypeOptions(){
      let html = ''
      ENTRY_TYPES.forEach( (val, key) => {
        html += `<option value="${val}">${key}</option>`
      })
      return html
    }

    function getHTMLRendered(isCommonmark){
      let html = ''
      ENTRY_TYPES.forEach( (val, key) => {
        html += `<option value="${val}">${key}</option>`
      })
      return html
    }
  
  }

}





