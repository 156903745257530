
"use strict";

import UIComponent from './UIComponent.js'



function decodeBase64UTF8String(content){
  return decodeURIComponent( escape( atob(content) ) )
}

function getEntryHTML(content){
  return decodeURIComponent( escape( atob(content) ) )
}

export default class BlogMod extends UIComponent{
	
	constructor(confString) {
    super('div', '#Blog')
    this.setHTML(`
      <div class="entries-box"> </div> 
    `)

    this.entriesBox = this.getElement('.entries-box') 

    this.entryCache = new Map()
    this.listEntries // Entries already loaded for the ?list query

  }


  async processModParams(params){
    log('BlogMod.processModParams', params)
    this.entriesBox.innerHTML = ''

    if (params.startsWith('list')){ 

      let filterQuery = params.split(':')[1] // e.g. list:size=30
      // status=any
      let getQuery = ''  // default
      if (filterQuery && filterQuery !== '') getQuery = filterQuery

      let response = await fetch('getEntryList?'+getQuery); // fetch('entries?id=css-vertical-align-issue');
      let entries = await response.json();
      log('Entry list', entries)

      entries.forEach( entryData => {
        this.addEntry(entryData, false)
      })

    }else{ // default: it shows the entry

      if (this.entryCache.has(params)){
        this.addEntry(this.entryCache.get(params), true)
      }else{
        let response = await fetch('getEntry?id='+params); // fetch('getEntry?id=css-vertical-align-issue');
        let entryData = await response.json();
        this.addEntry(entryData, true)
      }
    }


    function getLabelsHTML(labels){
      let html = ''
      labels.forEach( label => {
        if (label[0] !== '.') html += '<a href="#blog?list:tag='+label+'">'+label+'</a>'
      })
      return html
    }

  }


  addEntry(entryData, showContent = false){
      //log('addEntry', entryData)
    let content = decodeBase64UTF8String(entryData.Content)//entryData.Content//atob(entryData.Content)
    if (entryData.Labels !== null && entryData.Labels.includes('.commonmark')){
      content = this.getEntryHTML(content)
    }

    let newElement = document.createElement('div')
    newElement.className = 'blog-entry'

    newElement.innerHTML = `
        <div class="title"><a href="#blog?${entryData.Id}">${entryData.Title}</a></div>
    `

    if (showContent) newElement.innerHTML += `
      <div class="entry-tags-box">
        <span style="font-size: 0.8em"> ${entryData.Date.substring(0,10)} <span>
        ${getLabelsHTML(entryData.Labels)} 
      </div>
      <div class="text-content">${content}</div>
    `
    this.entriesBox.appendChild(newElement)

    function getLabelsHTML(labelArray){
      if (labelArray === null) return ''
      let html = ''
      labelArray.forEach( label => {
        html += `<span class="label"><a style="font-weight: normal" href="#blog?list:tag=${label}">${label}</a></span>`
      })
      return html
    }

  }

}





