
import UIComponent from './UIComponent.js'
import BlogMod from './BlogMod.js'
import EditorMod from './EditorMod.js'


// Global utility functions
window.log = (...msg) => { console.log(...msg) }
window.error = (...msg) => { console.error(...msg) }



/****** Application start up code ******/

/*
const appMenu = document.getElementById('app-menu')
appMenu.innerHTML = ` 
	<a class="menu-selected" href="#front">HOME</a> 
	<a href="#blog?list">BLOG</a>
`

appMenu.addEventListener("click", e => {
	log(e.target.tagName)
	if (e.target.tagName === 'A'){
		appMenu.querySelector('.menu-selected').className = ''
    	e.target.className = 'menu-selected'
	}
})
*/


const appElement = document.getElementById('app-placeholder')



const moduleMap = new Map([
	['blog', undefined],
	['editor', undefined],
])


/*
// IInitialization of the regular blog module 
let blogModule = new BlogMod()
blogModule.processModParams('list')
appElement.append(blogModule.e)
*/

// Initial URL fragment analysis 
processUrlHash()


window.addEventListener("hashchange", e => {
	log('hashchange')
    processUrlHash()    
})


function manageModules(moduleId, moduleParams){
	console.log('manageModules',moduleId, moduleParams)

	if (moduleId === ''){
		moduleId = 'blog'; moduleParams = 'list';
	} 
	
	if (moduleMap.has(moduleId)){
        let nextModule = moduleMap.get(moduleId)
		// If the module hasn't been loaded 
		if (!nextModule){// The module is not loaded yet
			if (moduleId === 'blog') nextModule = new BlogMod()
			else if (moduleId === 'editor') nextModule = new EditorMod()
			moduleMap.set(moduleId, nextModule)
		// log("MMMMMMM", moduleMap, nextModule)
		}
        if (moduleParams) nextModule.processModParams(moduleParams)

		// Show the selected module
		if (appElement.firstChild){
			if (appElement.firstChild === nextModule.e) return
			appElement.firstChild.replaceWith(nextModule.e)
		}else 
			appElement.append(nextModule.e)
	}else{ 
		document.location.hash = ''; 
		return 
	}
}




function processUrlHash(){
   let urlHash = document.location.hash.substring(1)
   console.log('urlHash', urlHash)

   if (urlHash.indexOf('?') >= 0){ // Module with params
   	const moduleId = urlHash.substring(0, urlHash.indexOf('?'))
	const moduleParams = urlHash.substring(urlHash.indexOf('?')+1)
		// console.log('urlHash', urlHash.indexOf('?'), moduleId, moduleParams )
	manageModules(moduleId, moduleParams)
   }else // Module without params, urlHash = moduleId
   	 manageModules(urlHash)
}




/*
document.addEventListener('keydown', e => {
    if (e.code == 'Escape') {
      e.preventDefault()
      ModalPopup.hideModal()
    }
})
*/


